import React from 'react'
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill, BsFacebook } from "react-icons/bs"
const SocialLinks = () => {

    const links = [
        {
            id: 1,
            child: (
                <>
                    Linkedin <FaLinkedin size={30} />
                </>
            ),
            href: 'https://www.linkedin.com/in/parash-shrestha-7813a5138',
            style: 'rounded-tr-md'
        }, {
            id: 2,
            child: (
                <>
                    Github <FaGithub size={30} />
                </>
            ),
            href: 'https://github.com/parash-shresh'
        },
        {
            id: 3,
            child: (
                <>
                    Facebook <BsFacebook size={30} />
                </>
            ),
            href: 'https://www.facebook.com/Parash.Shresh/',

        },
        {
            id: 4,
            child: (
                <>
                    Mail <HiOutlineMail size={30} />
                </>
            ),
            href: 'mailto:parash.shresh@gmail.com',
        },
        {
            id: 5,
            child: (
                <>
                    Resume <BsFillPersonLinesFill size={30} />
                </>
            ),
            href: '/parash.pdf',
            style: 'rounded-br-md',
            download: true,
        },


    ]
    return (
        // hidden lg:flex yetti garda social icon large screen ma matra aaune hunxa
        <div className="hidden lg:flex flex-col top-[35%] left-0 fixed">
            <ul>
                {links.map(({ id, child, href, style, download }) => (
                    <li key={id} className={"flex justify-between items-center w-40 h-14 px-4 bg-gray-500  ml-[-100px] hover:rounded-md duration-300 hover:ml-[-10px] " + " " + style}>

                        <a href={href} className="flex justify-between items-center text-white w-full "
                            download={download} target="_blank"
                            rel='noreferer'
                        >
                            <>
                                {child}
                            </>

                        </a>

                    </li>
                ))}



            </ul>
        </div>
    )
}

export default SocialLinks