import React from 'react'
import html from '../assets/html.png';
import css from '../assets/css.png';
import javascript from '../assets/javascript.png';
import reactImage from '../assets/react.png';
import nextJs from '../assets/nextjs.png';
import graphql from '../assets/graphql.png';
import github from '../assets/github.png';
import tailwind from '../assets/tailwind.png';
import wpLogo from '../assets/WP.png';
import youtubevideoEditor from '../assets/yt.png';
import figmaDesigner from '../assets/figma.png'



const Experiences = () => {
    const techs =[ 
            {
            id: 1,
            src:html,
            title: 'HTML',
            style: "shadow-orange-500"
            },
            {
            id: 2,
            src: css,
            title: 'CSS',
            style: "shadow-blue-500"
            },
            {
            id: 3,
            src:javascript,
            title: 'JAVASCRIPT',
            style: "shadow-yellow-500"
            },
            {
            id: 4,
            src:reactImage,
            title: 'REACT',
            style: "shadow-blue-600"
            },
            {
            id: 5,
            src:figmaDesigner,
            title: 'Figma Design',
            style: "shadow-sky-500"
            },
            {
            id: 6,
            src:tailwind,
            title: 'TAILWIND',
            style: "shadow-sky-500"
            },
            {
            id: 7,
            src:youtubevideoEditor,
            title: 'YoutubeVideo',
            style: "shadow-pink-500"
            },
            {
            id: 8,
            src:github,
            title: 'GitHub',
            style: "shadow-red-500"
            }
            ,
            {
            id: 9,
            src:wpLogo,
            title: 'WordPress',
            style: "shadow-red-500"
            }
            
            
        ]
  return (
    <div name="experiance" id='experience' className="bg-gradient-to-b from-blue-800 to-black w-full ">
        <div className="max-w-screen-ls max-w-screen-lg mx-auto p-4 flex flex-col justify-centerw-full h-full text-white">
            <div>
                <p className="text-4xl font-bold border-b-4 border-red-500 p-2 inline"> Experience</p>
                <p className="py-6">These are the technologies I've worked with</p>
            </div>

            <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">

                {techs.map(({id, src, title, style}) => (
                      <div key={id} className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}>
                      <img src={src} alt="" className="w-20 mx-auto" />
                      <p className="mt-4">{title}</p>
                  </div>
                ))}
              
            </div>
        </div>
    </div>
  )
}

export default Experiences