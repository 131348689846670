import React from 'react'
const Contact = () => {
  return (
    <div name="contact" id='contact' className="w-full bg-gradient-to-b from-black to-gray-500 p-4 text-white">
        <div className="flex flex-col p-4 justify-center mx-w-screen-lg max-w-screen-lg mx-auto h-full">
            <div className="pb-8">
                <p className="text-4xl font-bold inline border-b-4 border-blue-500">Let's Connect</p>
                <p className="py-6">Submit the form below to get in touch with me.</p>
            </div>

            <div className="flex justify-center items-center ">
                <form action="https://getform.io/f/7ffd5aa1-6d7e-4e73-ae90-ecc01c5d8ea9" method="POST" className=" flex flex-col w-full md:w-1/2">
                    <input type="text" name ="name" placeholder="ENter your name" className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none" />

                    <input type="text" name ="email" placeholder="ENter your Email" className="p-2 my-4 bg-transparent border-2 rounded-md text-white focus:outline-none" />

                    <textarea name="message"  id="" cols="30" rows="10" className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"></textarea>

                    <button className="text-white bg-gradient-to-b from-cyan-500 to-blue-500 px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-105 duration-300">Lets Talk</button>
                </form>
            </div>
        </div>
    </div>
  )
}

export default Contact