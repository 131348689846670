import React from 'react';
import heroImage from '../assets/a.png';
import heroImageAnother from '../assets/aa.jpg';
import { useState, useEffect } from 'react';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';

const Home = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const imageSources = [heroImage, heroImageAnother];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % imageSources.length);
        }, 15000); // Change image every 5 seconds (5000 milliseconds)

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div name="home" id='home' className="h-screen w-full bg-gradient-to-b from-black via-black to-gray-800">
            <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
                <div className="flex flex-col justify-center h-96"> {/* Set a fixed height */}
                    <h2 className="text-4xl sm:text-7xl font-bold text-white pr-4">
                        Parash Shrestha
                    </h2>
                    <h3 className="text-2xl text-white font-bold pt-4 font-parash">I am Web Developer</h3>
                    <p className="text-gray-500 py-4 max-w-md px-1">
                        I'm not just a developer; I'm an explorer of the digital cosmos. I'm always on the lookout for the latest technologies and trends, ready to boldly go where no code has gone before.
                    </p>
                    <div>
                        <button className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer">
                            Contact Me
                            <span className="group-hover:rotate-90 duration-300">
                                <a href="#contact"><MdOutlineKeyboardArrowRight size={25} className="ml-1" /></a>
                            </span>
                        </button>
                    </div>
                </div>

                <div className="h-96"> {/* Set a fixed height */}
                    <img
                        className="rounded-2xl mx-auto h-full w-2/3 md:w-full"
                        src={imageSources[currentImage]}
                        alt={`Image ${currentImage}`}
                    />
                </div>
            </div>
        </div>
    );
};

export default Home;
