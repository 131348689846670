import React from 'react'

const About = () => {
  return (
    <div name="about" id='about' className="w-full bg-gradient-to-b from-gray-800 to-black text-white py-16">
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full ">
        <div className=" pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-red-500">About</p>
        </div>
        <p className=" text-xl">
          I'm a passionate and creative full-stack web developer with a mission to transform digital ideas into stunning, functional realities. With a keen eye for design, a deep love for coding, and a knack for problem-solving, I've dedicated myself to the exciting world of web development.
        </p>
        <br />

        <div className=" pb-8" id='my-journey'>
          <p className="text-4xl font-bold inline border-b-4 border-red-500">My Journey</p>
        </div>
        <p className="text-xl">
          My journey in web development started with a burning curiosity. It was a desire to understand how websites worked, which eventually led me to discover the art and science of coding. Over the years, I've honed my skills, learning from every project and embracing the ever-evolving landscape of technology.
        </p>
        <br />

        <div className=" pb-8" id='whatido'>
          <p className="text-4xl font-bold inline border-b-4 border-red-500">What I Do</p>
        </div>
        <div className="text-xl">
          I specialize in a diverse range of technologies, with a focus on:

          <ul>
            <li >React.js: Crafting dynamic, user-centric front-end experiences.</li>

            <li>WordPress: Customizing and optimizing websites for seamless content management.</li>

            <li>Core PHP: Building robust back-end solutions and web applications.</li>
          </ul>
        </div>
        <br />

        <div className=" pb-8" id='myapproach'>
          <p className="text-4xl font-bold inline border-b-4 border-red-500">My Approach</p>
        </div>
        <div className="text-xl">
          I believe that successful web development is not just about writing lines of code but creating experiences. I'm committed to:

          <ul className='mt-4'>
            <li className='mb-2'><b>User-Centric Design:</b> Ensuring that every project is a joy to use, with a strong emphasis on UI/UX.</li>

            <li className='mb-2'><b>Efficiency:</b> Striving for the most efficient and effective solutions.</li>

            <li className='mb-2'><b>Continuous Learning:</b> Staying ahead of the curve with emerging technologies.</li>
          </ul>
        </div>
        <br />


        <div className=' to-black text-white w-full mt-16 mx-auto'>
          <div className='flex justify-around text-center space-x-16 '>

            <div
              className="transform  hover:bg-blue-600 transition duration-500 hover:scale-125"
            >
              <div className=" pb-2">
                <p className="text-2xl font-bold inline border-b-4 p-2 border-red-500">Project One</p>
              </div>
              <p className=" text-14  text-justify p-4">
               Ecommerce Website:
               <br />
              Powered by React.js, Html, Tailwind Css, I've created a cutting-edge platform where shopping meets technology. Featured a seamless, user-friendly, and secure online shopping experience. Explored a wide range of products, from fashion to electronics, all from the comfort of different screens. Helps to connect customer's dream product with the seller profiable business 
              in a verse of clicking.
              </p>
              <br />
            </div>
            <div
              className="transform bg-gradient-to-r from-blue-400 via-purple-500 to-pink-600 hover:from-blue-600 hover:via-purple-700 hover:to-pink-800 p-4 rounded-md transition duration-500 hover:scale-125"
            >
              <div className=" pb-2">
                <p className="text-2xl font-bold inline border-green-500 border-b-4 p-2">Project Two</p>
              </div>
              <p className=" text-1xl  text-justify p-4">
               Travel Agency Web App
               <br />
               A project close to my heart. I'm thrilled to present a platform that I've meticulously designed for fellow travel enthusiasts. This web app is the culmination of my passion for both web development and wanderlust. With it, I've aimed to simplify the travel planning process, making it easier for you to explore the world. From selecting dream destinations to booking accommodations and finding exciting experiences
              </p>
              <br />
            </div>
            <div
              className="transform  hover:bg-blue-600 transition duration-500 hover:scale-125"
            >
              <div className=" pb-2">
                <p className="text-2xl font-bold inline border-b-4 p-2 border-red-500">Project Three</p>
              </div>
              <p className="text-1xl  text-justify p-4">
              miscellaneous Projects <br />
              A project born out of a passion for storytelling and staying informed. With this platform, I've harnessed the capabilities of React.js to bring you a dynamic, interactive, and engaging space for both content creators and readers. Whether you're an avid blogger looking to share your insights or a news aficionado seeking the latest updates, our web app offers a seamless and feature-rich experience. 
              </p>
              <br />
            </div>
          </div>
        </div>



      </div>
    </div>
  )
}

export default About