import React from 'react'

const Footer = () => {
  return (
    <div >
      <footer className=" text-white footer-1 bg-gradient-to-b from-black via-black to-gray-800 pt-8 sm:pt-12">
        <div className="container px-4 mx-w-screen-lg max-w-screen-lg mx-auto h-full">
          <div className="sm:flex sm:flex-wrap sm:-mx-4 md:py-4">
            <div className="px-4 sm:w-1/2 md:w-1/4 xl:w-1/6">
              <h5 className="text-xl font-bold mb-6 text-white" >Navigate</h5>
              <ul className="list-none footer-links">
                <li className="mb-2">
                  <a href="#home" className="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Home</a>
                </li>
                <li className="mb-2">
                  <a href="#about" className="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">About</a>
                </li>
                <li className="mb-2">
                  <a href="#portfolio" className="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Portfolio</a>
                </li>
                <li className="mb-2">
                  <a href="#experience" className="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Experience</a>
                </li>
                <li className="mb-2">
                  <a href="#contact" className="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Contact</a>
                </li>
              </ul>
            </div>

            <div className="px-4 sm:w-1/2 md:w-1/4 xl:w-1/6 mt-8 md:mt-0">
              <h5 className="text-xl font-bold mb-6">About</h5>
              <ul className="list-none footer-links">
                <li className="mb-2">
                  <a href="#my-journey" className="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">My Journey</a>
                </li>
                <li className="mb-2">
                  <a href="#whatido" className="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">What I Do</a>
                </li>
                <li className="mb-2">
                  <a href="#myapproach" className="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">My Approach</a>
                </li>
                
              </ul>
            </div>
            <div className="px-4 sm:w-1/2 md:w-1/4 xl:w-1/6 mt-8 md:mt-0">
              <h5 className="text-xl font-bold mb-6">Contact</h5>
              <ul className="list-none footer-links">
                <li className="mb-2">
                  <a href="https://www.linkedin.com/in/parash-shrestha-7813a5138" target='_blank' className="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Linkedin</a>
                </li>
                <li className="mb-2">
                  <a href="mailto:parash.shresh@gmail.com" target='_blank' className="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Mail</a>
                </li>
                <li className="mb-2">
                  <a href="https://www.facebook.com/Parash.Shresh/" target='_blank' className="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Facebook</a>
                </li>
              </ul>
            </div>
            <div className="px-4 mt-4 sm:w-1/3 xl:w-1/6 sm:mx-auto xl:mt-0 xl:ml-auto">
              <h5 className="text-xl font-bold mb-6 sm:text-center xl:text-left">Find me here</h5>
              <div className="flex sm:justify-center xl:justify-start">
                <a href="https://www.facebook.com/Parash.Shresh/" className="w-8 h-8 border border-2 border-gray-400 rounded-full text-center py-1 text-gray-600 hover:text-white hover:bg-blue-600 hover:border-blue-600">
                  <i className="fab fa-facebook"></i>
                </a>
                <a href="https://www.linkedin.com/in/parash-shrestha-7813a5138" className="w-8 h-8 border border-2 border-gray-400 rounded-full text-center py-1 ml-2 text-gray-600 hover:text-white hover:bg-blue-400 hover:border-blue-400">
                  <i className="fab fa-linkedin "></i>
                </a>
                <a href="mailto:parash.shresh@gmail.com" className="w-8 h-8 border border-2 border-gray-400 rounded-full text-center py-1 ml-2 text-gray-600 hover:text-white hover:bg-red-600 hover:border-red-600">
                  <i className="fab fa-google-plus-g"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer